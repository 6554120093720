@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway+Dots&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sixtyfour&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
}

// Lady3.jpg

$max-width: 1200px;
$primary-color: rgb(194, 7, 41);
$secondary-color: #fff4;
$tertiary: rgb(95, 95, 238);
$white-color: #fff;
$black-color: #000;
$dim-color: #333;
$transitionEase: all 0.5s ease;

::selection{
    background-color: rgba($primary-color, 0.7);
    color: rgba($white-color, 0.5);
}

a{
    text-decoration: none;
    color: $black-color;
}

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 7px;
    font-size: 18px;
    padding: 10px 15px;
    transition: $transitionEase;
    box-shadow: 0px 0px 0px $primary-color;
    border-radius: 100px;
    border: none;
    font-weight: 600;

    @media screen and (max-width: 600px) {
        font-size: 15px;
        padding: 7px 10px;
    }

    &:hover{
        scale: 1.1;
    }

    span{
        color: $primary-color;
    }

    svg{
        transition: $transitionEase;
    }

    &.filled{
        background-color: $primary-color;
        color: $white-color;
        padding: 10px 30px;
        font-weight: 600;

        span{
            color: $white-color;
        }

        &:hover{
            box-shadow: 0px 0px 10px $primary-color;
        }
    }

    &.outline{
        background-color: transparent;
        color: $primary-color;
        border: 2px solid $primary-color;

        span{
            color: $primary-color;
        }

        &.white{
            color: $white-color;
            border-color: $white-color;

            span{
                color: $white-color;
            }
        }

        &:hover{
            background-color: $primary-color;
            box-shadow: 0px 0px 10px $primary-color;
            color: $white-color;

            span{
                color: $white-color;
            }
        }
    }

    &.animated{
        background-color: $primary-color;
        border: 2px solid #fff;
        // background-image: linear-gradient(-145deg, $secondary-color, $primary-color, $secondary-color);
        background-size: 200%;
        background-position: left;
        color: $white-color;
        box-shadow: 0px 10px 50px rgba($primary-color, 0.7);
        animation: bounce-vertical 1s ease forwards infinite alternate;

        &:hover{
            background-position: right;
        }
    }

    &.black{
        background-color: $black-color;
        color: $white-color;

        span{
            color: $primary-color;
        }

        &:hover{
            svg{
                transform: translateX(4px);
            }
        }
    }

    &.small{
        font-size: 15px;
    }
}

body{
    font-family: "Poppins", sans-serif;
    background-color: $primary-color;
}

.app{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    &.isLoading{
        *{
            animation-play-state: paused !important;
        }
    }
}

.page{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 5vw;
    animation: page-animation 0.5s ease forwards;
    background-color: $primary-color;
    color: #fff;

    .content{
        width: 100%;
        max-width: $max-width;
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        align-items: center;

        @media screen and (max-wdith: 900px) {
            padding-top: 70px;
        }
    }

    &.home{
        background-color: #000;
        .content{
            padding-top: 0px;
        }
        .section{
            width: 100%;
            height: 100vh;

            &.hero-section{
                padding-bottom: 170px;
                background-image: linear-gradient($primary-color, black);
                color: #fff;
                width: 100vw;
                padding: 100px 5vw;
                overflow: hidden;
                display: flex;
                padding-top: 200px;
                padding-bottom: 200px;
                justify-content: center;
                background-attachment: fixed;
                align-items: flex-start;
                height: fit-content;
                
                @media screen and (max-width: 1200px) {
                    min-height: 1000px;
                }
                // min-height: 100vh;
                
                @media screen and (max-width: 800px) {
                    background-image: linear-gradient(rgba($primary-color, 0.4), black), url(../assets/cougar-i.png);
                    background-size: cover;
                    padding-top: 140px;
                    background-color: $primary-color;
                    
                    background-position: 0px 100px;
                    background-repeat: no-repeat;
                }
                
                .max{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 40px;
                    position: relative;
                    width: 100%;
                    max-width: $max-width;

                    @media screen and (max-width: 800px)  {
                        display: flex;
                    }
                }

                .left{

                    position: relative;
                    z-index: 10;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 100%;

                    h3{
                        font-size: 30px;
                        font-weight: 400;
                    }
                    
                    h2{
                        font-size: 40px;
                    }
                    
                    h1{
                        font-weight: 900;
                        font-size: 80px;
                        margin-top: -10px;
                        max-width: 1700px;
                        color: #fff;
                        font-size: 100px;
                        min-width: max-content;

                        span{
                            font-family: "Courgette", cursive;
                            font-optical-sizing: auto;
                            font-weight: 400;
                            font-style: normal;
                        }

                    }

                    @media screen and (max-width: 800px) {
                        height: 100%;
                        width: 100%;
                        h1{
                            font-size: 70px;
                            margin-top: 30px;
                        }

                        p.small{
                            font-size: 30px;
                        }
                    }

                    @media screen and (max-width: 500px) {
                        h1{
                            font-size: 59px;
                            line-height: 100%;
                        }
                    }

                    @media screen and (max-width: 400px) {
                        h1{
                            font-size: 49px;
                            line-height: 100%;
                        }
                    }
                    
                    p{
                        &.small{
                            font-size: 30px;
                            font-weight: 600;
                            margin-bottom: 50px;
                            max-width: 900px;
                            margin-top: 20px;

                            @media screen and (max-width: 500px) {
                                font-size: 25px;
                                margin-bottom: 50px;
                            }

                            @media screen and (max-width: 400px) {
                                font-size: 20px;
                                margin-bottom: 30px;
                            }
                        }
                    }

                    form{
                        display: flex;
                        flex-direction: column;
                        background-color: #fff3;
                        backdrop-filter: blur(20px);
                        border: 1px solid #fff3;
                        border-radius: 15px;
                        width: 100%;
                        max-width: 600px;
                        box-shadow: 0px 0px 10px #0002;
                        max-height: 500px;
                        
                        .form-details{
                            padding: 30px;
                            overflow-y: scroll;
                        }

                        .head{
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            gap: 4px;
                            padding-top: 40px;
                            @media screen and (max-width: 600px) {
                                padding-top: 50px;
                            }

                            @media screen and (max-width: 450px) {
                                padding-top: 60px !important;
                            }

                            .steps-select{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                position: absolute;
                                right: 30px;
                                top: 10px;

                                @media screen and (max-width: 400px) {
                                    right: 0;
                                    width: 100%;
                                }

                                .step{
                                    padding: 4px;
                                    width: 30px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: #fff1;
                                    border-radius: 5px;
                                    font-weight: 600;

                                    &.active{
                                        background-color: #fff;
                                        color: $primary-color;
                                    }
                                }
                            }

                            h2{
                                @media screen and (max-width: 450px) {
                                    font-size: 30px;
                                }
                            }
                        }

                        p{
                            font-size: 18px;
                        }
                    }

                    .step-details{
                        border-top: 2px solid #fff2;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        margin-top: 20px;
                        padding-top: 30px;
                        cursor: default;
                        

                        .container{
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            margin-bottom: 30px;
                            gap: 10px;
                            row-gap: 20px;
                            
                            @media screen and (max-width: 600px) {
                                grid-template-columns: repeat(1, 1fr);
                            }

                            .side{
                                display: flex;
                                flex-direction: column;
                                gap: 7px;
                                z-index: 3;
                                
                                .selector{
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    left: 0;
                                    // top: 100%;
                                    backdrop-filter: blur(10px);
                                    background-color: #fff;
                                    color: $black-color;
                                    border-radius: 5px;
                                    overflow: hidden;
                                    transition: all 0.5s ease;
                                    display: none;

                                    &.active{
                                        display: flex;
                                    }

                                    .select{
                                        width: 100%;
                                        background-color: transparent;
                                        border-radius: 0px !important;
                                    }
                                }

                                .select{
                                    display: flex;
                                    justify-content: space-between;
                                    gap: 7px;
                                    align-items: center;
                                    padding: 10px;
                                    background-color: #fff2;
                                    border-radius: 5px;
                                    position: relative;


                                    p{
                                        font-weight: 600;
                                    }
                                }
                            }

                            .inp-holder{
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                input{
                                    background-color: #fff2;
                                    border: 2px solid #fff1;
                                    padding: 5px 10px;
                                    border-radius: 10px;
                                    transition: all 0.5s ease;
                                    color: #fff;

                                    &::placeholder{
                                        color: #aaa;
                                    }

                                    &:hover, &:focus{
                                        background-color: #fff;
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }

                    .btn{
                        width: 250px !important;
                        max-width: 100%;

                        p{
                            color: #fff;
                            font-size: 18px;
                            font-weight: 600;
                        }
                    }
                }



                .right{
                    background-image: url(../assets/cougar-i.png);
                    background-size: cover;
                    background-attachment: fixed;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 40px;
                    gap: 30px;
                    height: 100%;
                    width: 100%;
                    background-position: top center;
                    $bg1: #b59bff;
                    $bg2: #fab54d;
                    $bg3: #71d03c;

                    max-width: 700px;
                    position: absolute;
                    bottom: -90px;
                    right: -100px;

                    @media screen and (max-width: 800px) {
                        right: 0;
                        top: 100px;
                        display: none;
                    }
                    animation: opacity-in 1s ease forwards;
                    .row{
                        gap: 30px;
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        align-items: center;

                        &:nth-child(2){
                            margin: -145px 0;
                        }
                    }

                    @keyframes opacity-in {
                        0%{
                            opacity: 0;
                            filter: blur(10px);
                        }
                        
                        100%{
                            opacity: 1;
                            filter: blur(0px);
                        }
                    }

                    .img{
                        width: 260px;
                        height: 300px;
                        border-radius: 20px;
                        background-color: $primary-color;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        box-shadow: 0px 0px 30px #0001;
                        animation: translate-up 1s ease forwards;
                        transition: $transitionEase;
                        opacity: 0;

                        @keyframes translate-up {
                            0%{
                                top: 40px;
                                opacity: 0;
                            }
                            
                            100%{
                                top: 0;
                                opacity: 1;
                            }
                        }
                        
                        .img-holder{
                            min-height: 140%;
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            border-radius: inherit;
                            background-image: url(../assets/user-i.png);
                            background-size: cover;
                            background-position: center;
                            transition: $transitionEase;
                        }

                        &:hover{
                            scale: 1.1;
                            .img-holder{
                                filter: grayscale(10);
                            }    
                        }

                        &.i{
                            background-color: $bg1;
                            z-index: 2;
                            left: -60px;
                            transform: rotate(-10deg);
                            animation-delay: 0.1s;
                            
                            .img-holder{
                                background-image: url(../assets/user-i.png);
                            }
                        }
                        
                        &.ii{
                            left: 130px;
                            transform: rotate(10deg);
                            background-color: $bg2;
                            animation-delay: 0.3s;
                            
                            .img-holder{
                                background-image: url(../assets/user-iii.png);
                                background-position: -120px top ;
                                height: 100%;
                            }

                        }
                        
                        &.iii{
                            transform: rotate(-20deg);
                            left: -60px;
                            z-index: 3;
                            background-color: $bg3;
                            animation-delay: 0.5s;

                            .img-holder{
                                background-image: url(../assets/user-ii.png);
                            }
                        }
                    }
                }
            }

            &.section-ii{
                padding-top: 150px;
                width: 100vw;
                background-color: $black-color;
            }
        }
    }

    &.auth{
        background-image: linear-gradient(rgba($primary-color, 0.8), #000), url(../assets/Lady3.jpg);
        height: 100vh;
        background-size: cover;
        background-position: top center;
        .content{
            gap: 20px;
            padding-top: 150px;
            padding-bottom: 70px;

            .right{
                display: flex;
                flex-direction: column;
                position: relative;
                gap: 30px;
                justify-content: flex-start;
                align-items: center;
                animation: slide-up 1s ease forwards;
                background-color: #fff2;
                border: 2px solid #fff2;
                // background-color: rgba(darken($primary-color, 22), 0.7);
                backdrop-filter: blur(4px);
                color: $white-color;
                width: 100%;
                max-width: 700px;
                border-radius: 20px;
                padding: 50px 30px;
                box-shadow: 0px 0px 30px #0003 inset;
                overflow: hidden;

                &::before{
                    content: "";
                    position: absolute;
                    height: 150%;
                    top: -15%;
                    width: 100px;
                    background-color: #fff1;
                    z-index: -1;
                    filter: blur(30px);
                    pointer-events: none;
                    rotate: 15deg;
                    animation: scroll-blur 5s ease forwards infinite;
                    animation-delay: 2s;
                }
                
                form{
                    z-index: 1;
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;

                    & > div{
                        display: flex;
                        flex-direction: column;
                        gap: 3px;
                        width: 100%;
                        label{
                            font-weight: 600;
                        }
                        input, textarea{
                            width: 100%;
                            border: none;
                            color: #fff;
                            outline: none;
                            background-color: #fff6;
                            padding: 10px 15px;
                            border-radius: 20px;
                        }

                        textarea{
                            border-radius: 10px;
                            min-width: 100%;
                            max-width: 100%;
                            min-height: 100px;
                            max-height: 300px;
                        }
                    }
                }
            }
        }

        h1{
            font-size: 50px;
            color: $white-color;
        }
    }

    &.dashboard-page{
        .content{
            .heading{
                display: flex;
                justify-content: space-between;
                gap: 20px;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;
                border-bottom: 2px solid #eee;
                margin-bottom: 30px;

                h1{
                    font-size: 60px;
                    font-weight: 700;
                    
                    @media screen and (max-width: 500px) {
                        font-size: 40px !important;
                        font-weight: 300;
                    }
                }

                img, svg{
                    width: 100px;
                    height: 100px;
                    border-radius: 1000px;
                    object-fit: cover;
                    object-position: center;
                    background-color: $primary-color;
                    background-image: linear-gradient($secondary-color, $primary-color);
                    transition: all 0.5s ease;

                    &:hover{
                        transform: scale(1.05);
                    }
                }

                @media screen and (max-width: 700px) {
                    h1{
                        font-size: 50px;
                    }

                    img, svg{
                        width: 60px;
                        height: 60px;
                    }
                }
            }

            .welcome{
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 30px;
                p{
                    display: flex;
                    justify-content: flex-start;
                    gap: 10px;
                    align-items: center;

                    svg{
                        font-size: 18px;
                        color: #71d03c;
                    }
                }

                b{
                    font-size: 20px;
                }
            }

            .links-holderx{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                gap: 20px;
                flex-wrap: wrap;
                margin-bottom: 140px;

                .btn-card{
                    width: 300px;
                    height: 250px;
                    background-size: cover;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 20px;
                    transition: all 0.5s ease;
                    position: relative;
                    overflow: hidden;
                    background-color: #0004;

                    @media screen and (max-width: 500px) {
                        width: 100%;
                    }
                    
                    &:first-child{
                        background-image: linear-gradient(#0006, #0006), url(../assets/user-i.png);
                        background-color: #71d03c;
                    }
                    
                    &:nth-child(2){
                        background-image: linear-gradient(#0006, #0006), url(../assets/user-ii.png);
                        background-color: #b59bff;
                    }
                    
                    &:nth-child(3){
                        background-image: linear-gradient(#0006, #0006), url(../assets/user-iii.png);
                        background-color: #fab54d;
                    }

                    &:nth-child(4){
                        background-image: linear-gradient(#0006, #0006), url(../assets/user-iv.png);
                        background-color: #d03c3c;
                    }
                    
                    p{
                        position: relative;
                        z-index: 2;
                        font-weight: 600;
                        font-size: 23px;
                        text-shadow: 0px 0px $primary-color;
                        transition: all 0.5s ease;
                    }

                    &::before{
                        content: "";
                        position: absolute;
                        width: 120%;
                        height: 120%;
                        backdrop-filter: blur(10px);
                        background-color: #0004;
                        transition: all 0.5s ease;
                        pointer-events: none;
                        opacity: 0;
                    }

                    &:hover, &:focus{
                        transform: scale(1.05);

                        p{
                            transform: scale(1.05);
                            text-shadow: 2px 2px $primary-color;
                        }

                        &::before{
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }

        .info{
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;
            align-items: center;

            p{
                color: #fffc;
            }

            form{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    font-size: 14px;
                }
            }
        }

        .search-holder{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 30px;

            input{
                border: none;
                padding: 10px 15px;
                background-color: rgba($primary-color, 0.2);
                width: 100%;
                max-width: 400px;
                border-radius: 10px;
            }

            svg{
                width: 40px;
                height: 40px;
                padding: 10px;
                color: rgba(darken($primary-color, 22), 1);
            }
        }

        

        .btn-holder{
            display: flex;
            margin-top: 50px;
        }

    }

    &.profile{
        .profile-details{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            margin-bottom: 30px;

            & > div{
                display: flex;
                flex-direction: column;
                gap: 5px;

                p{
                    background-color: rgba($white-color, 0.1);
                    border: 2px solid #fff1;
                    padding: 10px 15px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;
                    min-height: 40px;
                }

                svg{
                    color: #71d03c;
                    font-size: 30px;
                }
            }
        }

        .update-holder{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;

            form{
                display: flex;
                flex-direction: column;
                gap: 30px;

                & > div{
                    display: flex;
                    flex-direction: column;
                    gap: 3px;

                    label{
                        font-weight: 600;
                    }

                    input, textarea{
                        padding: 10px 15px;
                        background-color: rgba($white-color, 0.1);
                        border: 2px solid #fff1;

                        &::placeholder{
                            color: #fff7;
                        }
                        color: #fff;
                        outline: none;
                        border-radius: 10px;
                        transition: all 0.5s ease;

                        &:hover, &:focus{
                            background-color: rgba($white-color, 0.2);
                        }
                    }

                    textarea{
                        min-height: 100px;
                        max-height: 500px;
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                    }
                }

                button{
                    margin-top: 20px;
                }
            }
        }

        .footer{
            margin-top: 100px;
        }
    }

    &.user-profile{
        .img-holder{
            margin-top: 20px;
            height: 300px;
            width: 100vw;
            background-image: linear-gradient(#fff, #fffc), url(../assets/Lady3.jpg);
            background-size: cover;
            position: absolute;
            z-index: -1;
            filter: blur(50px);
            top: 80px;
        }
        
        .profile-img{
            width: 200px;
            height: 200px;
            background-image: url(../assets/Lady3.jpg);
            background-size: cover;
            border-radius: 1000px;
            box-shadow: 0px 0px 130px rgba($primary-color, 0.1);
            margin-bottom: 50px;
            transition: all 0.5s ease;
            margin-top: 20px;

            &:hover{
                transform: scale(1.05);
                filter: grayscale(10);
            }

        }

        .details{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            h1{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
                font-size: 40px;
                padding-bottom: 30px;
                border-bottom: 2px solid #eee;
                width: 100%;
                margin-bottom: 10px;

                svg{
                    font-size: 30px;
                }
            }

            .btwn{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 700px;
            }

            .city{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                padding: 7px 15px;
                border-radius: 1000px;
                color: $white-color;
                background-color: $primary-color;
            }

            .bio{
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 700px;
                gap: 3px;

                label{
                    font-weight: 600;
                }

                p{
                    width: 100%;
                    padding: 10px 15px;
                    background-color: rgba($primary-color, 0.1);
                    border-radius: 10px;
                    min-height: 100px;
                }
            }
        }

        .gallery{
            width: 100%;
            max-width: 700px;
            gap: 10px;
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            gap: 3px;
            display: none;

            label{
                font-weight: 600;
            }

            .scroller{
                width: 100%;
                display: flex;
                justify-content: flex-start;
                overflow-y: scroll;
                padding-bottom: 30px;
                gap: 10px;
            }

            .photo{
                min-width: 300px;
                height: 250px;
                background-color: #0002;
                border-radius: 10px;
                transition: all 0.3s ease;

                &:hover{
                    transform: translateY(10px);
                }
            }
        }

        .services{
            width: 100%;
            max-width: 700px;
            display: flex;
            flex-direction: column;
            gap: 3px;
            display: none;

            label{
                font-weight: 600;
            }
        }

        .contact{
            width: 100%;
            max-width: 700px;
            display: flex;
            flex-direction: column;
            gap: 3px;
            margin-bottom: 50px;

            label{
                font-weight: 600;
            }

            .links{
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 10px;

                a{
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($primary-color, 0.2);
                    border: 2px solid rgba($primary-color, 0.2);
                    font-size: 14px;
                    border-radius: 10px;
                    transition: all 0.4s ease;
                    &:hover{
                        background-color: rgba($primary-color, 0.7);
                        color: #fff;
                        transform: scale(1.05);
                    }

                }
            }
        }
    }

    &.download-page{
        .info{
            width: 100%;
            text-align: center;
            color: #777;
            font-size: 20px;
            margin: 30px 0;

            span{
                font-weight: 600;
            }
        }

        .features-holder{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;
            width: 100%;
            // display: none;
            
            .feature{
                height: 100px;
                padding: 0 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #0008;
                color: #fff;
                border-radius: 10px;
                display: none;
            }
        }

        button{
            margin-top: 50px;
            gap: 10px;
            margin-bottom: 100px;
            font-size: 24px;
            padding: 10px 30px;
        }
    }

    &.not-found{
        .content{
            gap: 30px;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding-top: 0px;
            text-align: center;
        }

        h1{
            font-size: 100px;
            line-height: 100px;
        }

        p{
            color: #666;
        }
    }
}

.footer{
    width: 100vw;
    padding: 0 5vw;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #000;
    color: #fff;
    flex-direction: column;
    display: none;
    @media screen and (max-width: 800px){
        justify-content: flex-start;
    }

    .content{
        padding-top: 30px;
        border-top: 1px solid #333;
        width: 100%;
        max-width: 900px;
        display: flex;
        flex-direction: column;
        gap: 80px;

        .top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .logo{
                font-size: 40px;
                font-weight: 600;
            }

            .links-holder{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                a{
                    color: #fff;
                }
            }

            .social-links{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                a{
                    color: #666;
                    font-size: 20px;
                    &:nth-child(2){
                        color: $tertiary;
                    }
                }

            }

            @media screen and (max-width: 800px) {
                flex-direction: column;
                gap: 20px;
                align-items: center;
                justify-content: flex-start;

                .links-holder{
                    align-items: center;
                    text-align: center;
                    flex-direction: column;
                    margin-bottom: 50px;
                    margin-top: 20px;
                }
            }
        }

        .bottom{
            color: #666;
            font-weight: 500;
        }
    }
}

.breaker{
    width: 80%;
    height: 3px;
    background-color: $primary-color;
    border-radius: 500%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        transform: scale(0.7);
        width: 100%;
    }

    &::before{
        content: "";
        position: absolute;
        width: 100px;
        height: 50px;
        background-image: url(../assets/hearts.png);
        background-size: 50px;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
    }

    &.white{
        background-color: $white-color;
        &::before{
            background-color: $primary-color;
            background: none;
            background-color: #fff;
            width: 20px;
            height: 20px;
            border-radius: 2px;
            transform: rotate(45deg);
        }

        &::after{
            content: "";
            position: absolute;
            height: 20px;
            width: 20px;
            border: 2px solid $white-color;
            border-radius: 2px;
            opacity: 0.2;
            transform: scale(2) rotate(45deg);
            animation: opacitate 1s linear forwards infinite;
        }
    }
}

.header{
    display: flex;
    backdrop-filter: blur(10px);
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 5vw;
    background-color: rgba($primary-color, 0.7) !important;
    // backdrop-filter: blur(20px);
    position: fixed;
    z-index: 90;
    top: 0;
    left: 0;
    transition: $transitionEase;
    border-bottom: 1px solid #fff9;

    &.show{
        background-color: transparent;
    }

    .content{
        width: 100%;
        max-width: $max-width;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        gap: 70px;

        .logo-holder{
            width: 100px;
            min-width: 100px;
            font-size: 24px;
            font-weight: 600;

            @media screen and (max-width: 600px) {
                width: 50px;
            }

            .logo-el{
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .links-holder{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 50px;
            width: 100%;

            a{
                font-weight: 500;
                transition: $transitionEase;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                color: #fff;

                &::before{
                    content: "";
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    background-color: $primary-color;
                    z-index: -5;
                    border-radius: 1000px;
                    filter: blur(20px);
                    opacity: 0;
                    transform: scale(0);
                    transition: $transitionEase;
                }

                p{
                    z-index: 2;
                    position: relative;
                    color: inherit;
                    background-image: inherit;
                    -webkit-background-clip: inherit;
                    background-clip: inherit;
                    -webkit-text-fill-color: inherit;
                }

                &:hover{
                    transform: scale(1.05);

                    &::before{
                        transform: scale(1);
                        opacity: 0.3;
                    }
                }
                
                &.active, &.big{
                    color: #fff;
                    transform: scale(1.05);
                    border: 2px solid #fff3;
                    background-color: #fff2;
                    padding: 10px 15px;
                    border-radius: 10px;
                    
                    &.default{
                        background-image: none;
                        color: $dim-color !important;
                        -webkit-text-fill-color: $dim-color;
                        transform: scale(1);
                    }

                    &.big{
                        letter-spacing: 2px;
                        font-weight: 600;
                    }
                }
            }
        }

        .account-holder{
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            position: relative;

            &::before{
                content: "";
                position: absolute;
                z-index: 0;
                width: 100%;
                height: 150%;
            }

            span{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 7px;
                background-color: rgba($white-color, 0.2);
                color: $white-color;
                border: 2px solid #fff2;
                padding: 10px 20px;
                border-radius: 1000px;
                font-weight: 600;
                z-index: 10;

                svg{
                    transition: $transitionEase;
                    font-size: 15px;
                }
            }

            .actions{
                position: absolute;
                padding: 10px;
                width: 100%;
                background-color: rgba($white-color, 1);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                gap: 4px;
                z-index: 2;
                border-radius: 20px;
                transition: $transitionEase;
                overflow: hidden;
                top: calc(100% + 10px);
                transform: translateY(50%);
                opacity: 0;
                backdrop-filter: blur(10px);
                z-index: 20;
                pointer-events: none;

                a{
                    border-radius: 0px;
                    width: 100%;
                    color: $primary-color;

                    &:hover{
                        opacity: 0.8;
                    }
                }

                button{
                    padding: 10px 10px;
                    color: $dim-color;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    border: none;
                    box-shadow: 0px 0px 10px #0003;
                    cursor: pointer;
                    transition: $transitionEase;
                    opacity: 0;
                    pointer-events: none;
                    background-color: $primary-color;
                    color: $white-color;

                    svg{
                        font-size: 14px;
                        transition: $transitionEase;
                    }

                    &:hover{
                        svg{
                            opacity: 0.5;
                        }
                        box-shadow: 0px 0px 20px #0003;
                        transform: scale(1.05);
                    }
                }

            }

            &:hover{
                span{
                    svg{
                        transform: rotate(-180deg);
                    }
                }
                .actions{
                    transform: translateY(0);
                    opacity: 1;
                    pointer-events: all;

                    button{
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }


        }

        .hamburger{
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border-radius: 10px;
            cursor: pointer;
            transition: $transitionEase;
            display: none;
            
            .bar{
                position: absolute;
                height: 5px;
                width: 80%;
                border-radius: 1000px;
                background-color: #fff;
                transition: $transitionEase;

                &.i{
                    transform: translateY(-5px);
                }
                
                &.ii{
                    transform: translateY(5px);
                }
            }

            &.show{
                transform: scale(0.7) rotate(180deg);
                .bar{
                    &.i{
                        transform: translateY(0);
                        transform: translateY(0) rotate(-45deg);
                    }

                    &.ii{
                        transform: translateY(0) rotate(45deg);
                    }
                }
            }
        }

        @media screen and (max-width:950px) {
            padding: 10px 0;
            .links-holder{
                display: none;
            }

            .account-holder{
                display: none;
            }

            .hamburger{
                display: flex;
            }
        }
    }
}

.menu{
    position: fixed;
    z-index: 89;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    padding: 70px 5vw;
    background-color: rgba($primary-color, 0.7);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $transitionEase;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    button{
        position: fixed;
        bottom: 30px;
        background-color: darken($primary-color, 17);
        color: $white-color;
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 600;
    }

    .links-holder{
        display: flex;
        flex-direction: column;
        gap: 10px;

        a{
            font-size: 40px;
            transition: all 0.5s ease;
            transform: translateY(30px);
            opacity: 0;
            filter: blur(10px);
            color: #fff;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media screen and (max-width: 700px) {
                font-weight: 300;
            }
            
            @media screen and (max-height: 690px) {
                font-weight: 300;
                font-size: 35px;
            }

            &::before{
                content: "";
                position: absolute;
                width: 5px;
                height: 0%;
                left: 0;
                border-radius: 1000px;
                background-color: #fff4;
                transition: all 0.5s ease;
                opacity: 0.5;
            }

            &.active{
                color: #fff !important;
                position: relative;
                padding-left: 20px !important;

                &::before{
                    opacity: 1;
                    height: 50%;
                }
            }

            &.default{
                -webkit-text-fill-color: $dim-color;
                color: $dim-color;
            }

            &:hover{
                padding: 20px 10px;

                &::before{
                    opacity: 0.3;
                    height: 20%;
                }
            }
        }
    }

    &.show{
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        .links-holder{
            a{
                transform: translateY(0);
                opacity: 1;
                filter: blur(0px);
            }
        }
    }
}

.preloader{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($primary-color, 0.8);
    z-index: 300;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    .circle{
        width: 60px;
        height: 60px;
        border: 8px solid rgba($primary-color, 0.1);
        border-top: 8px solid $primary-color;
        border-radius: 1000px;
        animation: spin-clockwise 1s linear forwards infinite;
    }

}

::-webkit-scrollbar{
    width: 0px;
    height: 5px;

    @media screen and (max-width: 1100px) {
        width: 0px;
    }
}

::-webkit-scrollbar-button{
    height: 40px;
    width: 0px;
}

::-webkit-scrollbar-thumb{
    background-color: $primary-color;
    border-radius: 1000px;
    
    &:hover{
        background-color: darken($primary-color, 2);
    }
}

h1, h2, h3{
    span{
        &.bigger{
            @media screen and (max-width: 600px) {
                font-size: 50px;
            }
        }
    }
}

//animations
@keyframes switch-bg-right {
    0%{
        background-size: 200%;
        background-position: left;
    }
    
    100%{
        background-size: 200%;
        background-position: right;
    }
}

@keyframes spin-clockwise {
    0%{
        transform: rotate(-360deg);
    }
}

@keyframes bounce-vertical {
    0%{
        transform: translateY(10px);
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes opacitate {
    0%{
        opacity: 0.3;
        transform: scale(0) rotate(45deg);
    }
    100%{
        opacity: 0;
        transform: scale(3) rotate(45deg);
    }
}

@keyframes slide-up-small {
    0%{
        transform: translateY(30px);
        opacity: 0;
        filter: blur(10px);
    }
}

@keyframes slide-up {
    0%{
        transform: translateY(100px);
        opacity: 0;
        filter: blur(10px);
    }
}

@keyframes scroll-blur {
    0%{
        left: -200%;
    }
    100%{
        left: 200%;
    }
}

@keyframes page-animation {
    0%{
        opacity: 0;
        scale: 1.1;
    }
    100%{
        opacity: 1;
        scale: 1;
    }
}

button{
    cursor: pointer;
}

.logo-el{
    width: 170px;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: transparent;
    // background-image: url(../assets/logo.png);
    filter: invert(10);

    img{
        width: 100%;
        filter: grayscale(10);
    }
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 600px) {
        width: 150px;
        height: 40px;
    }
}

:disabled{
    opacity: 0.4;
    cursor: not-allowed;
}

.selector{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index: 10;
    width: fit-content;

    p{
        padding: 10px 15px;
        background-color: rgba($primary-color, 0.1);
        border-radius: 10px;
        text-transform: capitalize;
    }

    .options{
        position: absolute;
        top: 90%;
        padding: 10px 5px;
        display: flex;
        flex-direction: column;
        gap: 3px;
        background-color: #fffc;
        backdrop-filter: blur(10px);
        animation: slide-up-small 0.5s ease forwards;
        border: 2px solid #ccc;
        border-radius: 10px;
        display: none;

        .option{
            padding: 3px 5px;
            cursor: pointer;
            &:hover{
                color: $primary-color;
            }
        }
    }

    &:hover{
        .options{
            display: flex;
        }
    }
}

.items-holder{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 30px;

    .service{
        width: 100%;
        border: 2px solid rgba($primary-color, 0.1);
        border-radius: 10px;
        padding: 10px 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        transition: all 0.5s ease;

        &:hover{
            transform: scale(1.05);
            z-index: 10;
        }

        .provider-img{
            width: 60px;
            min-width: 60px;
            height: 60px;
            border-radius: 10px;
            background-color: #0003;
            background-size: cover;
            background-position: center;
            background-image: url(../assets/Lady3.jpg);
        }

        .provider-details{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2px;

            p{
                &:first-child{
                    font-size: 18px;
                    font-weight: 600;
                    color: #333;
                }
                
                &:nth-child(2){
                    color: #666;
                    font-size: 14px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 7px;

                    span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 10px;
                        font-size: 10px;
                        background-color: rgba($primary-color, 0.7);
                        color: #fff;
                        gap: 10px;
                        border-radius: 1000px;
                    }
                }
            }
        }

        .charge{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        position: relative;
        
        @media screen and (max-width: 600px) {
            padding-right: 50px;
            .charge{
                font-size: 13px;
            }

            .provider-details{
                p{
                    &:nth-child(2){
                        span{
                            padding: 2px 7px;
                        }
                    }
                }
            }
        }

        .options{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            min-width: 40px;
            height: 40px;
            margin-left: 10px;
            border-radius: 10px;
            border: 4px solid #0000;
            transition: all 0.5s ease;
            cursor: pointer;
            @media screen and (max-width: 600px) {
                position: absolute;
                z-index: 3;
                right: 10px;
                background-color: #fff;
                box-shadow: 0px 0px 20px #fff;
            }


            &:hover{
                .options-holder{
                    display: flex;
                }
            }

            .options-holder{
                position: absolute;
                padding: 10px;
                display: flex;
                flex-direction: column;
                right: 0;
                top: 100%;
                padding: 5px;
                background-color: #fffc;
                backdrop-filter: blur(10px);
                border: 2px solid #ccc;
                border-radius: 10px;
                gap: 3px;
                display: none;
                animation: slide-up-small 0.5s ease forwards;

                a{
                    font-size: 14px;
                    padding: 5px 10px;
                    transition: all 0.5s ease;
                    font-weight: 600;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 7px;

                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

.select-holder{
    display: flex;
    flex-direction: column;
    gap: 2px;

    .css-1nmdiq5-menu{
        color: #333;
    }
}

.bar{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    input{
        width: 100%;
    }

    .filter{
        min-width: fit-content;
    }
}

.admin{
    .barx{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: fit-content;
        max-width: 100%;
        gap: 10px;
        margin-bottom: 30px;
    }
    
    .data-holder{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 900px;
        gap: 5px;
        
        @media screen and (max-width: 850px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .service, .user{
        width: 100%;
        border: 2px solid rgba($primary-color, 0.1);
        border-radius: 10px;
        padding: 10px 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        transition: all 0.5s ease;

        &:hover{
            transform: scale(1.05);
            z-index: 10;
        }

        .provider-img{
            width: 60px;
            min-width: 60px;
            height: 60px;
            border-radius: 10px;
            background-color: #0003;
            background-size: cover;
            background-position: center;
            background-image: url(../assets/Lady3.jpg);
        }

        .provider-details{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2px;

            p{
                &:first-child{
                    font-size: 18px;
                    font-weight: 600;
                    color: #333;
                }
                
                &:nth-child(2){
                    color: #666;
                    font-size: 14px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 7px;

                    span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 10px;
                        font-size: 10px;
                        background-color: rgba($primary-color, 0.7);
                        color: #fff;
                        gap: 10px;
                        border-radius: 1000px;
                    }
                }
            }
        }

        .charge{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        position: relative;
        
        @media screen and (max-width: 600px) {
            padding-right: 50px;
            .charge{
                font-size: 13px;
            }

            .provider-details{
                p{
                    &:nth-child(2){
                        span{
                            padding: 2px 7px;
                        }
                    }
                }
            }
        }

        .options{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            min-width: 40px;
            height: 40px;
            margin-left: 10px;
            border-radius: 10px;
            border: 4px solid #0000;
            transition: all 0.5s ease;
            cursor: pointer;
            @media screen and (max-width: 600px) {
                position: absolute;
                z-index: 3;
                right: 10px;
                background-color: #fff;
                box-shadow: 0px 0px 20px #fff;
            }


            &:hover{
                .options-holder{
                    display: flex;
                }
            }

            .options-holder{
                position: absolute;
                padding: 10px;
                display: flex;
                flex-direction: column;
                right: 0;
                top: 100%;
                padding: 5px;
                background-color: #fffc;
                backdrop-filter: blur(10px);
                border: 2px solid #ccc;
                border-radius: 10px;
                gap: 3px;
                display: none;
                animation: slide-up-small 0.5s ease forwards;

                a{
                    font-size: 14px;
                    padding: 5px 10px;
                    transition: all 0.5s ease;
                    font-weight: 600;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 7px;

                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

.page{
    .top-user-profiles{
        display: flex;
        flex-direction: column;
        min-height: 500px;
        margin-bottom: 90px;
        width: 100vw;
        
        @media screen and (min-width: 1600px) {
            width: 100%;
            border-radius: 15px;
            overflow: hidden;
        }
    }
}

.scroll-down{
    width: 100vw;
    position: fixed;
    left: 0px;
    top: 0;
    height: 100%;
    z-index: 30;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1vw;

    .left, .right{
        display: flex;
        flex-direction: column;
        gap: 10px;

        svg{
            transform: scale(0.75);
            color: #fff4;
            animation: bubble-in 1s ease forwards;
            opacity: 0;
            transition: all 1s ease;
            &.active{
                color: #fff;
                transform: scale(1.05);
            }
        }
    }
}

@keyframes bubble-in {
    0%{
        opacity: 0;
    }
    
    100%{
        opacity: 1;
    }
}

.footx{
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    z-index: 10;
    position: fixed;
    bottom: 0;
    padding: 10px 5vw;
    padding-top: 20vh;
    background-image: linear-gradient(180deg, #0000, #0004, #0008, #000c, #000);
    z-index: 20;
    width: 100vw;
    animation: opacity-in 1s ease forwards;
    pointer-events: none;

    .max{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: $max-width;
    }
    
    .leftx{
        display: flex;
        gap: 10px;
        color: #fffa;
        font-weight: 600;
        justify-content: flex-start;
        align-items: center;

        .links-holder{
            display: flex;
            gap: 5px;
            pointer-events: all;
            font-size: 24px;
        }
    }

    .rightx{
        display: flex;
        gap: 15px;
        font-size: 20px;
    }

    .link{
        color: #fff;
    }
    
    a{
        color: #fff;
    }
}

.login-link{
    position: absolute;
    top: -50px;
    color: #fffa;
    right: 0;
    
    @media screen and (max-width:1200px) {
        top: -30px;
    }

    a{
        font-weight: 600;
        color: #fff;
        transition: all 0.5s ease;

        &:hover{
            transform: scale(1.05);
        }
    }
}

.success-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 200;
    background-color: #000c;
    padding: 0 5vw;

    .success-card{
        max-width: 500px;
        background-color: $primary-color;
        color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 30px;
        text-align: center;
        max-height: 70vh;
        overflow: hidden;
        animation: scale-in 0.5s ease forwards;

        img{
            width: 70px;
        }
    }

    @keyframes scale-in{
        0%{
            transform: scale(0);
            opacity: 0;
        }
        100%{
            transform: scale(1);
            opacity: 1;
        }
    }
}

.install-info{
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff2;
    border: 2px solid #fff2;
    border-radius: 20px;
    padding: 20px;
    margin-top:50px;
    margin-bottom: 20px;
    max-width: 700px;
    animation: scale-in 1s ease forwards;
    position: relative;
    backdrop-filter: blur(10px);

    @media screen and (max-width: 500px) {
        margin-top: 20px;

        &.drop{
            margin-top: -60px !important;
        }
    }

    .img{
        width: 70px;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%) translateX(20%);
        
        @media screen and (max-width: 500px) {
            width: 50px;
            transform: translateY(-50%) translateX(-20%);
        }

        img{
            width: 100%;
        }
    }

    .info-tag{
        width: 25px;
        height: 25px;
        border-radius: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid;
        font-size: 15px;
    }

    a{
        padding: 10px 20px;
    }
}